import './AgreementPage.scss'

export const AgreementPage = () => {
  return (
    <main className="c-agreement">
      <div className="c-agreement__content">
        <section className='c-agreement__header'>
          <h2>Einverständniserklärung zur Massagebehandlung</h2>
        </section>
        <section>
          <p>Vor deiner ersten Massage bei mir, möchte ich dich über einige wichtige Aspekte meiner Massagen informieren. Bitte lies dir die folgenden Punkte sorgfältig durch und bestätigen die Punkte mit deiner Einverständnis, indem du die entsprechende Checkbox bei der Buchung deines Termins über Calendly anhakst.</p>
        </section>
        <section>
          <h3>1. Natur der Massage</h3>
          <p>Ich verstehe, dass die von Isabelle Petry durchgeführten Massagen eine spezielle Form der Gesundheitsvorsorge darstellen, die darauf abzielt, Spannungen zu lösen und das Wohlbefinden zu fördern. Mir ist bewusst, dass diese Massagen Stressbewältigung und die Regenerationsfähigkeit unterstützen, jedoch nicht zur Behandlung spezifischer Krankheiten gedacht sind und ärztliche Behandlungen nicht ersetzen können.</p>
        </section>
        <section>
          <h3>2. Umfang der Behandlung</h3>
          <p>Ich verstehe die Wichtigkeit, Isabelle Petry über meine individuellen Bedürfnisse und spezifische gesundheitliche Aspekte wie Krampfadern, künstliche Gelenke oder nicht vollständig verheilte Narben zu informieren. Dies ermöglicht eine auf meine persönlichen Bedürfnisse abgestimmte Massage. Ich werde daher eventuelle Besonderheiten oder Bereiche, die besondere Aufmerksamkeit benötigen oder von der Berührung ausgeschlossen werden sollen, vor Beginn der Massage mitteilen, um meine Massageerfahrung optimal zu gestalten.</p>
        </section>
        <section>
          <h3>3. Nachwirkungen der Massage</h3>
          <p>Mir ist bekannt, dass es nach der Massage zu einem Gefühl tiefer Entspannung kommen kann, das möglicherweise meine Reaktionsfähigkeit beeinflusst. Ich verpflichte mich, im Straßenverkehr und bei anderen Aktivitäten besondere Vorsicht walten zu lassen.</p>
        </section>
        <section>
          <h3>4. Umgang mit ungewöhnlichen Reaktionen</h3>
          <p>Sollten als Folge der Massage ungewohnte körperliche oder psychische Reaktionen auftreten, übernehme ich die volle Verantwortung für den Umgang damit und werde bei Bedarf ärztlichen Rat einholen. Ich erkenne hiermit an, dass Isabelle Petry für solche Reaktionen oder deren Folgen nicht haftbar gemacht werden kann.</p>
        </section>
        <section>
          <h3>5. Stornierungsrichtlinien</h3>
          <p>Ich verpflichte mich, Termine, die ich nicht wahrnehmen kann, rechtzeitig abzusagen, um Isabelle Petry die Möglichkeit zu geben, den Termin neu zu vergeben. Mir ist bewusst, dass für Absagen, die weniger als 24 Stunden vor dem Termin erfolgen, eine Stornogebühr von 50-100% des Anwendungswertes erhoben werden kann.</p>
        </section>
        <section>
          <h3>6. Datenschutz und Vertraulichkeit</h3>
          <p>Ich stimme zu, dass meine persönlichen Daten von Isabelle Petry sicher verwahrt und ausschließlich zur Bearbeitung meiner Anfrage genutzt werden. Ich bin darüber informiert, dass meine Kundenakte nach Abschluss der Behandlung für die Dauer von drei Jahren aufbewahrt und anschließend vernichtet wird.</p>
        </section>
        <section>
          <p>Durch das Anhaken der Checkbox bei meiner Buchung auf Calendly erkläre ich mein vollständiges Einverständnis mit den oben genannten Bedingungen.</p>
        </section>
        {/* <footer className='c-footer'>
          <Button classtype='primary' onClick={() => window.close()}>Schließen</Button>
        </footer> */}
      </div>
    </main>
  )
}
