import { Button } from '../components/button/button'
import WalkingWomenJPG from '../assets/walking-women.jpg'
import WalkingWomenWebP from '../assets/walking-women.webp'
import Leaf from '../assets/leaf.svg'
import './HomePage.scss'

export const HomePage = () => {
  return (
    <div className="c-homepage">
      <header className="c-homepage__header">
        <h2>Sisterhood Massagen</h2>
      </header>
      <div className="c-spacer-1">
      </div>
      <div className='c-homepage__scroll-container'>
        <main className='c-homepage__container'>
          <picture className='c-homepage__walking-women'>
            <source srcSet={WalkingWomenWebP} type="image/webp" />
            <source srcSet={WalkingWomenJPG} type="image/jpeg" />
            <img src={WalkingWomenJPG} alt="Zwei Frauen, die Arm in Arm gehen, symbolisieren Verbundenheit und Unterstützung"></img>
          </picture>
          <div className='large-text'>Diese Webseite ist auf dem Weg zu dir! Gib ihr noch etwas Zeit.</div>
          <div className="c-spacer-2">
            <img src={Leaf} alt="Ein Blatt zur Trennung der beiden Texte"></img>
          </div>
          <div className='c-homepage__welcome'>
            <h3 className="">Willkommensgruß</h3>
            <div>Ich biete dir als Neukundin</div>
            <div className='c-homepage__discount'>einen Rabatt von 10 Euro</div>
            <div className='c-homepage__discount-post-text'>auf deine <u>erste</u> Massage bei mir an. So kannst du kostengünstig ausprobieren, ob dir meine Arbeit gut tut.</div>
            <div className='c-homepage__discount-post-text'>Schreibe einfach: &quot;Hi-Sister!&quot; in das Hinweis/Kommentar-Feld in meinem Buchungstool.</div>
          </div>
          <div className='c-homepage__opening-hours'>
            <h3>Ort</h3>
            <div>Hebammenpraxis Bargteheide<br />Heinrich-Hertz-Straße 23a,<br />22941 Bargteheide
            </div>
          </div>
          <div className='c-homepage__opening-hours'>
            <h3>Praxiszeiten</h3>
            <ul>
              <ol>Montag und Mittwoch Abend</ol>
              <ol>Samstag Ganztägig</ol>
            </ul>
          </div>
        </main>
      </div>
      <div className="c-spacer-1">
      </div>
      <footer className='c-homepage__footer'>
        <Button classtype='primary' onClick={() => window.open('https://calendly.com/sisterhoodmassagen', '_blank')}>Finde deinen Termin</Button>
      </footer>
    </div>
  )
}
