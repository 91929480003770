import { RouteObject } from 'react-router-dom'
import { HomePage } from './HomePage'
import { AgreementPage } from './AgreementPage'

export const AppRoutes = {
  Home: () => '/',
  Agreement: () => '/einverstaendnis'
}

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <HomePage />,
    children: [
      {
        index: true,
        element: <HomePage />
      }
    ]
  },
  {
    path: '/einverstaendnis',
    element: <AgreementPage />
  }
]
