import { ButtonHTMLAttributes, MouseEventHandler, ReactNode } from 'react'
import './button.scss'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  classtype?: 'primary' | 'secondary';
  size?: 'small' | 'medium';
  className?: string;
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

/**
 * Primary UI component for user interaction
 */
export const Button = ({
  classtype = 'secondary',
  className,
  size = 'medium',
  children,
  ...props
}: ButtonProps) => {
  return (
    <button
      {...props}
      className={`c-button c-button--${classtype} c-button--${size} ${className ?? ''}`}
      disabled={props.disabled}
    >
      {children}
    </button>
  )
}
