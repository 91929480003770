import { useRoutes } from 'react-router-dom'
import './App.scss'
import { routes } from './pages/router'

export const App = () => {
  const routesElement = useRoutes(routes)

  return (
    <div className="App">
      {routesElement}
    </div>
  )
}
